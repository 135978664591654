<template>
  <div>
    <slot
      name="select-component"
      :events="{ handlerCheckMenu, handlerSelectOrderType }"
      :computeds="{ orderTypeIsLocal, showScanQrMessage, getOrderType }"
    />
  </div>
</template>
<script>
import {
  orderTypeName,
  orderTypeStoreActions,
  orderTypeStoreGetters,
} from "@/store/modules/orderType/constNames";
import { axiosInstance } from "@/plugins/axios";
import { menuStoreActions } from "@/store/modules/menuProducts/constNames";
import { settingsDigitalLetterStoreGetters } from "../../../store/modules/settingsDigitalLetter/constNames";

export default {
  name: "SelectOrderTypeContainer",
  async mounted() {
    const getOrderType = this.getOrderType;
    const tableId = localStorage.getItem("tableId");
    const orderType =
      getOrderType ??
      (tableId ? orderTypeName.LOCAL : orderTypeName.TO_PICK_UP);
    this.handlerSelectOrderType(orderType);
    this.tableId = tableId;
    const globalConfigs = await axiosInstance.get(
      "api/v1/settings/domicile_pickup/",
      {
        headers: {
          Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
          "Content-Type": "application/json",
          Site: process.env.VUE_APP_SITE,
          Client: "app",
        },
      }
    );
    const { block_domicile_order, block_pickup_order } =
      globalConfigs.data.data;
    this.domicile_order = block_domicile_order;
    this.pickup_order = block_pickup_order;
  },
  data() {
    return {
      tableId: null,
      domicile_order: false,
      pickup_order: false,
    };
  },
  computed: {
    getOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    orderTypeIsLocal() {
      const orderType = this.getOrderType;
      return orderType === orderTypeName.LOCAL;
    },
    showScanQrMessage() {
      return this.orderTypeIsLocal && !this.tableId;
    },
  },
  methods: {
    handlerSelectOrderType(key, updateOptions) {
      if (!this.handlerCheckMenu(key)) return;

      this.$store.dispatch(orderTypeStoreActions.SAVE_ORDER_TYPE, {
        name: key,
        preselected: true,
      });
      this.$store.dispatch("cart/updateProductPriceDependencyOrderType", key);
      this.$store.dispatch(
        menuStoreActions.UPDATE_MENU_PRODUCTS_PRICES,
        this.orderTypeIsLocal
      );

      updateOptions && updateOptions(key);
    },

    handlerCheckMenu(key) {
      const checkMenu = {
        LOCAL:
          this.$store.getters[
            settingsDigitalLetterStoreGetters.GET_LOCAL_ORDERS
          ],
        AT_HOME: !this.domicile_order,
        TO_PICK_UP: !this.pickup_order,
      };
      return checkMenu[key] || false;
    },
  },
};
</script>
